import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id="about-us" className="bg-bg-secondary pt-16 p-12 flex justify-center">
        <div className="max-w-3xl">
            <h2 className="text-4xl md:text-5xl font-bold text-primary mb-6 text-center">
              {t('aboutUs.title')}
            </h2>
            <div className="space-y-6">
                <p className="text-primary text-justify">
                  {t('aboutUs.paragraph1')}
                </p>
                <p className="text-primary text-justify">
                  {t('aboutUs.paragraph2')}
                </p>
                <ul className="list-disc list-inside text-primary space-y-2">
                  <li>{t('aboutUs.bulletPoints.reliability')}</li>
                  <li>{t('aboutUs.bulletPoints.companyCulture')}</li>
                  <li>{t('aboutUs.bulletPoints.academicCollaboration')}</li>
                </ul>
            </div>
        </div>
    </div>
  );
};

export default AboutUs;