import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './localization/en.json';
import de from './localization/de.json';
import { InitOptions } from 'i18next';

const options: InitOptions = {
  resources: {
    de: { translation: de },
    en: { translation: en }
  },
  fallbackLng: 'de',
  lng: 'de',
  supportedLngs: ['en', 'de'],
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ['path', 'localStorage', 'navigator'],
    lookupFromPathIndex: 0,
    caches: ['localStorage']
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

export default i18n;