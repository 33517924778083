import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import WizzgridModules from './components/WizzGridModules';
import References from './components/References';
import FeaturesSection from './components/Features';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Introduction from './components/Introduction';
import Benefits from './components/Benefits';
import CookieConsent from './components/CookieConsent';
import { logPageView } from './utils/analytics';
import './i18n';
import DataSecuritySection from './components/DataSecuritySection';

const MainContent = () => {
  const { i18n } = useTranslation();
  const params = useParams<{ lang: string }>();

  useEffect(() => {
    const supportedLanguages = ['en', 'de'];
    if (params.lang && supportedLanguages.includes(params.lang)) {
      i18n.changeLanguage(params.lang);
    }
    logPageView();
  }, [params.lang, i18n]);

  return (
    <div>
      <Header />
      <Hero />
      <Introduction />
      <FeaturesSection />
      <Benefits />
      <WizzgridModules />
      <References />
      <AboutUs />
      <DataSecuritySection />
      <Contact />
      <Footer />
      <CookieConsent />
    </div>
  );
};

const DefaultLanguageRedirect = () => {
  const { i18n } = useTranslation();
  const defaultLang = i18n.language || 'de';
  return <Navigate to={`/${defaultLang}`} replace />;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLanguageRedirect />} />
        <Route path="/:lang" element={<MainContent />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;