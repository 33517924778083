import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-bg-secondary text-primary py-6">
            <div className="container mx-auto px-4">
                <p className="text-center">
                    <Trans i18nKey="footer.copyright" values={{ company: 'iContest' }}>
                        © <b>iContest</b>. All rights reserved.
                    </Trans>
                </p>
            </div>
        </footer>
    );
};

export default Footer;