import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import BookDemoModal from './BookDemoModal';

interface BookDemoButtonProps {
  buttonText?: string;
}

const BookDemoButton: React.FC<BookDemoButtonProps> = ({ buttonText }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const defaultButtonText = t('bookDemo.buttonText');

  return (
    <>
      <Button
        onClick={openModal}
        className="bg-primary text-secondary hover:bg-secondary hover:text-primary transition-colors font-medium px-4 py-2 rounded-md"
      >
        {buttonText || defaultButtonText}
      </Button>
      <BookDemoModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default BookDemoButton;