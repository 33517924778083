import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon1 } from '../assets/images/svg/icons/about_1.svg';
import { ReactComponent as Icon2 } from '../assets/images/svg/icons/about_2.svg';
import { ReactComponent as Icon3 } from '../assets/images/svg/icons/about_3.svg';
import { ReactComponent as Icon4 } from '../assets/images/svg/icons/about_4.svg';
import { ReactComponent as Icon5 } from '../assets/images/svg/icons/about_5.svg';
import { ReactComponent as Icon6 } from '../assets/images/svg/icons/about_6.svg';

const FeaturesSection: React.FC = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: Icon1,
      title: t('features.monitoring.title'),
      description: t('features.monitoring.description')
    },
    {
      icon: Icon2,
      title: t('features.networkOptimization.title'),
      description: t('features.networkOptimization.description')
    },
    {
      icon: Icon3,
      title: t('features.ar.title'),
      description: t('features.ar.description')
    },
    {
      icon: Icon4,
      title: t('features.capexOpex.title'),
      description: t('features.capexOpex.description')
    },
    {
      icon: Icon5,
      title: t('features.compliance.title'),
      description: t('features.compliance.description')
    },
    {
      icon: Icon6,
      title: t('features.demandSideResponse.title'),
      description: t('features.demandSideResponse.description')
    }
  ];

  return (
    <div id="features" className="bg-bg-secondary py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center mb-8">
            <h1 className="text-5xl font-bold text-primary">{t('features.sectionTitle')}</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6">
                <feature.icon className="w-12 h-12 mb-4 [&_path]:stroke-primary" />
                <h3 className="text-xl font-semibold mb-2 text-primary">{feature.title}</h3>
                <p className="text-primary break-word text-justify">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;