import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

import ref1 from '../assets/images/png/references/ref1.png';
import ref2 from '../assets/images/png/references/ref2.png';
import ref3 from '../assets/images/png/references/ref3.png';
import ref4 from '../assets/images/png/references/ref4.png';
import ref5 from '../assets/images/png/references/ref5.png';
import ref6 from '../assets/images/png/references/ref6.png';
import ref7 from '../assets/images/png/references/ref7.png';

const references = [
  { id: 'ref1', name: 'E.ON', image: ref1 },
  { id: 'ref2', name: 'Audax', image: ref2 },
  { id: 'ref3', name: 'MVM', image: ref3 },
  { id: 'ref4', name: 'OPUS TITASZ', image: ref4 },
  { id: 'ref5', name: 'MAVIR', image: ref5 },
  { id: 'ref6', name: 'CET', image: ref6 },
  { id: 'ref7', name: 'OMV', image: ref7 },
];

const ReferenceImage: React.FC<{ reference: typeof references[0] }> = ({ reference }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-20 px-4">
      <img
        src={reference.image}
        alt={t('references.altText', { name: reference.name })}
        className="max-h-16 max-w-full object-contain"
      />
    </div>
  );
};

const ReferenceSlider: React.FC = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="references" className="bg-white">
      <div className="container mx-auto px-4 pb-8">
        <div className="flex flex-col items-center mt-14 mb-8">
          <h1 className="text-4xl md:text-5xl font-bold text-primary">{t('references.sectionTitle')}</h1>
          <h3 className="text-xl text-primary mt-4 text-center">
            <span>{t('references.subtitle1')}</span>
            <br/>
            <span>{t('references.subtitle2')}</span>
          </h3>
        </div>
        {isMobile ? (
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView="auto"
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            centeredSlides={false}
            className="flex items-center"
          >
            {references.map((reference) => (
              <SwiperSlide key={reference.id} className="!w-auto">
                <ReferenceImage reference={reference} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4 justify-items-center">
            {references.map((reference) => (
              <ReferenceImage key={reference.id} reference={reference} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceSlider;