import React from 'react';
import { useTranslation } from 'react-i18next';
import Trapezoid from './Trapezoid';

const WizzgridModules: React.FC = () => {
  const { t } = useTranslation();

  const modules = [
    {
      title: t('wizzgridModules.operations.title'),
      description: t('wizzgridModules.operations.description')
    },
    {
      title: t('wizzgridModules.fieldSupport.title'),
      description: t('wizzgridModules.fieldSupport.description')
    },
    {
      title: t('wizzgridModules.analytics.title'),
      description: t('wizzgridModules.analytics.description')
    },
    {
      title: t('wizzgridModules.flexibility.title'),
      description: t('wizzgridModules.flexibility.description')
    }
  ];

  return (
    <div id="wizzgrid-modules" className="container mx-auto pt-16 py-8">
      <div className="flex flex-col items-center mb-8">
        <h1 className="md:text-5xl lg:text-5xl text-3xl font-bold text-primary">
          {t('wizzgridModules.sectionTitle')}
        </h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-7xl mx-auto">
        {modules.map((module) => (
          <Trapezoid key={module.title} title={module.title} description={module.description} />
        ))}
      </div>
    </div>
  );
};

export default WizzgridModules;