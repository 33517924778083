import React from 'react';
import { useTranslation } from 'react-i18next';
import screensImage from '../assets/images/png/screens.png';
import BookDemoButton from './BookDemoButton';

const Introduction: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id="introduction" className="bg-white py-16">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-12 mb-8 xl:mr-16 lg:mb-0">
          <div className="text-lg text-primary mb-6 break-words text-justify">
            <p>{t('introduction.descriptionFirst')}</p>
            <p>{t('introduction.descriptionSecond')}</p>
          </div>
          <BookDemoButton buttonText={t('introduction.demoButton')} />
        </div>
        <div className="lg:w-1/2 flex justify-center">
          <img 
            src={screensImage} 
            alt={t('introduction.imageAlt')} 
            className="w-[70%] h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;