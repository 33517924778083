import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as Hex1 } from '../assets/images/svg/hero/1.svg';
import { ReactComponent as Hex2 } from '../assets/images/svg/hero/2.svg';
import { ReactComponent as Hex3 } from '../assets/images/svg/hero/3.svg';
import { ReactComponent as Hex4 } from '../assets/images/svg/hero/4.svg';
import AnimatedSvgWrapper from './AnimatedSvgWrapper';
import AnimatedCursorArrow from './AnimatedCursor';

const hexComponents = [Hex1, Hex2, Hex3, Hex4];

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const texts = [
    t('hero.text1'),
    t('hero.text2'),
    t('hero.text3'),
    t('hero.text4')
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentHexIndex, setCurrentHexIndex] = useState(0);
  const [useInitialAnimation, setUseInitialAnimation] = useState(true);

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setUseInitialAnimation(false);
    }, 3200);

    return () => clearTimeout(initialTimeout);
  }, []);

  useEffect(() => {
    if (!useInitialAnimation) {
      const interval = setInterval(() => {
        setCurrentTextIndex((prev) => (prev + 1) % texts.length);
        setTimeout(() => {
          setCurrentHexIndex((prev) => (prev + 1) % hexComponents.length);
        }, Math.floor(Math.random() * 1500) + 500);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [useInitialAnimation, texts.length]);

  const CurrentHexComponent = hexComponents[currentHexIndex];

  return (
    <AnimatedCursorArrow>
      <div className="hero-section bg-bg-secondary relative overflow-hidden">
        <div className="container mx-auto px-4 min-h-[412px] md:h-[412px] flex flex-col md:flex-row items-center justify-between">
          <div className="relative z-10 w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0 flex flex-col">
            <motion.h1 
              className="text-primary text-6xl sm:text-6xl lg:text-8xl font-bold"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {t('hero.title')}
            </motion.h1>
            <div className="h-[120px] md:h-[150px] flex items-center">
              <AnimatePresence mode='wait'>
                <motion.p 
                  key={currentTextIndex}
                  className="text-primary text-xl sm:text-2xl lg:text-4xl md:max-w-none"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.8 }}
                >
                  {texts[currentTextIndex]}
                </motion.p>
              </AnimatePresence>
            </div>
          </div>
        </div>
        <div className="absolute right-0 bottom-0 w-full md:w-1/2 h-[250px] md:h-[388px] mt-6 pointer-events-none">
          {useInitialAnimation ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <AnimatedSvgWrapper>
                <Hex1 
                  className="absolute right-0 bottom-0 w-auto h-full object-contain object-right-bottom transition-opacity duration-500" 
                />
              </AnimatedSvgWrapper>
            </motion.div>
          ) : (
            <CurrentHexComponent className="absolute right-0 bottom-0 w-auto h-full object-contain object-right-bottom transition-opacity duration-500" />
          )}
        </div>
      </div>
    </AnimatedCursorArrow>
  );
};

export default Hero;