import React from 'react';
import { useTranslation } from 'react-i18next';
import DarzLogo from '../assets/images/png/darz.jpg';

const DataSecuritySection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white pt-16">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-center mb-8">
            <img src={DarzLogo} alt="DARZ Logo" className="w-full max-w-[300px] h-auto" />
          </div>
          
          <h2 className="text-3xl font-bold text-primary mb-6 text-center">
            {t('dataSecuritySection.titleFirstLine')}
            <br />
            <span className="text-primary font-bold text-3xl">{t('dataSecuritySection.titleSecondLine')}</span>
          </h2>
          <p className="text-primary mb-6 text-justify">{t('dataSecuritySection.description')}</p>
          
          <div className="bg-bg-secondary p-6 rounded-lg mb-6">
            <h3 className="text-xl font-semibold mb-4">{t('dataSecuritySection.certifications')}</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>{t('dataSecuritySection.cert1')}</li>
              <li>{t('dataSecuritySection.cert2')}</li>
              <li>{t('dataSecuritySection.cert3')}</li>
              <li>{t('dataSecuritySection.cert4')}</li>
            </ul>
          </div>
          
          <p className="mt-6 text-primary text-justify">{t('dataSecuritySection.dataCenter')}</p>
          <div className="mt-8">
            <a 
              href="https://da-rz.de/de/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-primary font-bold hover:underline underline sm:no-underline"
            >
              {t('dataSecuritySection.learnMore')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSecuritySection;